import { SENTRY_DSN } from '@/app.constants';
import { OnlineDispatcher } from '@/components/online-dispatcher';
import { ApolloProvider } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { setupIonicReact } from '@ionic/react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import { IntlWrapper } from './components/intl-wrapper';
import { RootProvider } from './context/root-context';
import './index.css';
import { client } from './ir-apollo-client';
import { Root } from './root';
import { lockScreenOrientation, parseBoolEnv } from './services/helper-service';
import { register } from './serviceWorker';

setupIonicReact();
window.onlineDispatcher = new OnlineDispatcher();

if (
  process.env.NODE_ENV !== 'development' &&
  parseBoolEnv(import.meta.env.VITE_SENTRY_ENABLED)
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0,
  });
}

if (Capacitor.getPlatform() !== 'web') {
  Keyboard.setScroll({ isDisabled: false });
}

const startApp = () => {
  lockScreenOrientation();

  (document as any).querySelector('html').style.height = '100%';

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <RootProvider>
      <ApolloProvider client={client}>
        <IntlWrapper>
          <Router>
            <RecoilRoot>
              <Root />
            </RecoilRoot>
          </Router>
        </IntlWrapper>
        <ToastContainer />
      </ApolloProvider>
    </RootProvider>,
  );
};
register();
startApp();
