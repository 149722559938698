import { getCurrency } from '@/currencySymbols';
import { useIntl } from 'react-intl';
import { SubscriptionPeriod } from './app-plan.types';
import { Promo } from './promo';
import { addClickHandler } from '@/services/helper-service';

export type PlanTileProps = {
  onTabTileClick(id: string): Promise<void>;
  id: string;
  price: number;
  discountPrice?: number;
  currency: string;
  discount?: number;
  isYearlyPlan?: boolean;
  period: SubscriptionPeriod;
  discountId?: string | null;
  customDiscountLabel?: string | null;
};

export const PlanTile = ({
  price,
  discountPrice,
  currency,
  discount,
  onTabTileClick,
  id,
  isYearlyPlan,
  period,
  discountId,
  customDiscountLabel,
}: PlanTileProps) => {
  const { formatMessage: t } = useIntl();
  const onClick = () => {
    onTabTileClick(id);
  };

  return (
    <div
      className='relative rounded-[4px] w-[49%] max-w-[155px] h-[185px] laptop:max-w-unset not:first:ml-[20px]'
      {...addClickHandler(onClick)}
    >
      <div className='relative pb-[20px] overflow-hidden w-full h-full rounded-[4px] border border-[var(--secondary)] bg-[var(--mainBackground)]'>
        <div className='flex mb-0 flex-col items-center justify-center h-full'>
          <span className='font-bold text-[11px] leading-[13px] tracking-[0.5px] uppercase text-[var(--emphasized)] opacity-70 block'>
            {t({
              id: `menu.profile.plan.proposals.${
                period === SubscriptionPeriod.monthly ? '1' : '2'
              }.period`,
            })}
          </span>

          <span className='mt-[3px] font-bold text-[24px] leading-[28px] text-center text-[var(--textMain)]'>
            {`${discountPrice ?? price} ${getCurrency(currency)}`}
          </span>

          <span className='mt-[2px] font-normal text-[12px] leading-[14px] text-center text-[var(--textMain)] opacity-70'>
            {t({
              id:
                customDiscountLabel ??
                `menu.profile.plan.proposals.${
                  period === SubscriptionPeriod.monthly ? '1' : '2'
                }.per-period`,
            })}
          </span>

          {!!discount && (
            <>
              <div className='absolute top-0 right-0 w-0 h-0 border-0 border-solid border-transparent border-b-[47px] border-r-[47px] border-r-[#ef2d2d]'>
                <span className='w-[45px] absolute top-[9px] left-[8px] font-bold text-[13px] leading-[12px] text-center text-[#fff] transform rotate-[45deg]'>{`-${discount}%`}</span>
              </div>

              <div className='absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[101%] h-[20px] bg-[var(--secondary)] flex justify-center items-center'>
                <div className='absolute top-[-4px] w-0 h-0 border-0 border-solid border-transparent border-r-[19px] border-l-[19px] border-b-[12px] border-b-[var(--secondary)]' />
                <span className='z-[1] mt-[1px] font-black text-[10px] leading-[12px] tracking-[1px] uppercase text-[#fff]'>
                  {t({ id: 'menu.profile.plan.proposals.best-value' })}
                </span>
              </div>
            </>
          )}

          <button className='h-[36px] w-[140px] bg-[var(--emphasized)] text-white rounded-[18px] my-[7px]'>
            {t({ id: 'app.buy' })}
          </button>

          <span className='text-[10px] text-[var(--textMain)] opacity-60 text-center mb-[5px]'>
            {t({ id: 'app.trial-tree-days' })}
          </span>

          <Promo />
        </div>
      </div>

      {isYearlyPlan && (
        <div className='translate-y-1/2 w-fit font-normal text-[10px] leading-[12px] text-[var(--textMain)] opacity-50'>
          <span>*</span>
          {t({ id: 'menu.profile.plan.proposals.info-1' })}
          <span>{(price / 12).toFixed(2)}</span>
          <span className='plan__info-price'>{getCurrency(currency)}</span>
          {t({ id: 'menu.profile.plan.proposals.info-2' })}
        </div>
      )}
    </div>
  );
};
