import { AppType, LocalReceipt } from '@/core.types';
import blobStream from 'blob-stream';
import { format } from 'date-fns';

// @ts-ignore
import { DATE_MASK } from '@/app.constants';
import { hasAppAccess } from '@/services/app-service';
import PDFMerger from 'pdf-merger-js/browser';
import PDFHeader from './pdf-header.jpg';
import { generatePDFDocumentTable } from './pdf-table';
import {
  getLocalImage,
  getBase64FromUrl,
  isFileUrlPdf,
  resolveFilePath,
} from '../helper-service';

export function downloadBlob(blob: Blob, name = 'file.pdf') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
}

const createPageWithTableData = (receipts: LocalReceipt[]): Promise<Blob> => {
  return new Promise(async (resolve) => {
    const PdfTableClass = generatePDFDocumentTable();
    const doc = new PdfTableClass({
      margins: {
        top: 130,
        left: 20,
        right: 20,
        bottom: 10,
      },
      size: 'A4',
    });

    const robotoRegular = await fetch(
      `${import.meta.env.VITE_PUBLIC_URL}/Roboto-Regular.ttf`,
    ).then((response) => response.arrayBuffer());

    doc.font(robotoRegular);

    const stream = doc.pipe(blobStream());

    const totalSum = receipts.reduce((sum, { amount }) => sum + amount, 0);

    const rows = receipts.map(({ name, amount, receiptDate }) => [
      name || 'Untitled receipt',
      amount,
      format(new Date(receiptDate * 1000), DATE_MASK),
    ]);

    rows.push(['Total', totalSum, '']);

    const table = {
      headers: [
        {
          label: 'Name',
          headerColor: '#a2adeb',
          padding: 10,
        },
        {
          label: 'Amount',
          headerColor: '#a2adeb',
          padding: 10,
        },
        {
          label: 'Date',
          headerColor: '#a2adeb',
          padding: 10,
        },
      ],
      columnSpacing: 10,
      rows,
    };

    const [image, imageBase64] = await Promise.all([
      getLocalImage(PDFHeader),
      getBase64FromUrl(PDFHeader),
    ]);

    doc.image(imageBase64, 0, 0, {
      width: image.width,
      height: image.height,
    });

    // @ts-expect-error
    doc.table(table, {
      prepareHeader: () => doc.fontSize(12).fillColor('#313F85'),
      prepareRow: () => doc.fontSize(12).fillColor('#000'),
      width: 560,
    });
    doc.end();
    stream.on('finish', function () {
      // get a blob you can do whatever you like with
      const blob: Blob = stream.toBlob('application/pdf');
      resolve(blob);
    });
  });
};

export const generatePdfFromReceiptList = async (
  receipts: LocalReceipt[],
): Promise<Blob> => {
  const pdfReceiptFiles: Blob[] = [];
  const pdfReceipts = receipts.filter(({ imagePath }) =>
    isFileUrlPdf(imagePath),
  );
  const imageReceipts = receipts.filter(
    ({ imagePath }) => !isFileUrlPdf(imagePath),
  );

  const imagesPDF: Blob = await new Promise(async (resolve) => {
    const doc = new window.PDFDocument({ autoFirstPage: false });
    const stream = doc.pipe(blobStream());

    for await (const receipt of imageReceipts) {
      const src = resolveFilePath(receipt.imagePath);
      const [image, imageBase64] = await Promise.all([
        getLocalImage(src),
        getBase64FromUrl(src),
      ]);
      doc.addPage({
        size: [image.width, image.height],
      });

      doc.image(imageBase64, 0, 0, {
        width: image.width,
        height: image.height,
      });
    }

    // end and display the document in the iframe to the right
    doc.end();
    stream.on('finish', function () {
      // get a blob you can do whatever you like with
      const blob: Blob = stream.toBlob('application/pdf');
      resolve(blob);
    });
  });

  for await (const pdfReceipt of pdfReceipts) {
    const src = resolveFilePath(pdfReceipt.imagePath);
    const response = await fetch(src);
    const pdfBlob = await response.blob();
    pdfReceiptFiles.push(pdfBlob);
  }
  const firstPDF =
    receipts.length === 1 || hasAppAccess(AppType.idocument)
      ? null
      : await createPageWithTableData(receipts);

  const merger = new PDFMerger();
  if (firstPDF) {
    pdfReceiptFiles.unshift(firstPDF);
  }
  pdfReceiptFiles.push(imagesPDF);
  await Promise.all(pdfReceiptFiles.map((file) => merger.add(file)));

  return merger.saveAsBlob();
};
