import { AppButton } from '@/components/app-button';
import c from 'classnames';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type AppModalProps = {
  open: boolean;
  onClose(event: any, reason: string): void;
  content: ReactNode;
  title: string;
  className?: string;
  onSaveClick?(): void | Promise<void>;
  saveButtonLabel?: string | ReactNode;
  saveDisabled?: boolean;
};

export const AppModal = ({
  open,
  onClose,
  content,
  onSaveClick,
  saveDisabled,
  title,
  className = '',
  saveButtonLabel,
}: AppModalProps) => {
  const { formatMessage: t } = useIntl();

  if (!open) {
    return null;
  }

  return (
    <div className='fixed top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center bg-[var(--textMain10)]'>
      <div
        className={c(
          'font-roboto w-[343px] bg-[var(--mainBackground)] shadow-[0_0_20px_rgba(0,0,0,0.85)] rounded-[14px] box-border relative p-[18px]',
          className,
        )}
      >
        <div className='text-center font-medium text-[17px] text-[var(--textMain)] px-[30px] mb-[8px]'>
          {title}
        </div>
        <div className='app-modal__body'>{content}</div>
        <div className='flex gap-2 mt-2'>
          <AppButton
            className='rounded-[10px] h-[40px] w-full font-roboto font-medium text-[14px] leading-4 text-white bg-[var(--secondary)] text-center'
            onPress={onClose}
          >
            {t({ id: 'cancel' })}
          </AppButton>
          {onSaveClick && (
            <AppButton
              disabled={saveDisabled}
              className='rounded-[10px] h-[40px] w-1/2 font-roboto font-medium text-[14px] leading-4 text-white bg-[var(--emphasized)] text-center'
              onPress={onSaveClick}
            >
              {saveButtonLabel || t({ id: 'save' })}
            </AppButton>
          )}
        </div>
      </div>
    </div>
  );
};
