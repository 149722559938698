import { useRootContext } from '@/context/root-context';
import { RECOGNITION_LANGUAGES, RECOGNITION_TYPES } from '@/core.types';
import {
  SegmentChangeEventDetail,
  SelectChangeEventDetail,
} from '@ionic/react';
import { useState } from 'react';
import { useIntl } from 'react-intl';

export const useRecognitionLogic = () => {
  const { formatMessage: t } = useIntl();
  const { recognitionConfig, setRecognitionConfig } = useRootContext();
  const [recognitionType, setRecognitionType] = useState<RECOGNITION_TYPES>(
    recognitionConfig?.recognitionType,
  );
  const [recognitionLanguages, setRecognitionLanguages] = useState<string[]>(
    recognitionConfig.languages,
  );

  const recognitionSwitchOptions = Object.values(RECOGNITION_TYPES).map(
    (value) => ({
      value,
      label: t({ id: `recognition.${value}` }),
    }),
  );
  const recognitionLanguageSelectOptions = Object.entries(
    RECOGNITION_LANGUAGES,
  ).map(([key, value]) => ({
    value,
    label: t({ id: `menu.lang.${key}` }),
  }));

  const onRecognitionTypeChange = ({
    detail: { value },
  }: CustomEvent<SegmentChangeEventDetail>) => {
    if (value) {
      setRecognitionType(value as RECOGNITION_TYPES);
    }

    value === RECOGNITION_TYPES.none && setRecognitionLanguages([]);
  };

  const onRecognitionLanguagesChange = ({
    detail: { value },
  }: CustomEvent<SelectChangeEventDetail>) => {
    setRecognitionLanguages(value);
  };

  const onSaveButtonClick = () => {
    // TODO set config in application
    setRecognitionConfig({
      languages:
        recognitionType !== RECOGNITION_TYPES.none ? recognitionLanguages : [],
      recognitionType,
    });
  };

  return {
    recognitionSwitchOptions,
    recognitionType,
    onRecognitionTypeChange,
    recognitionLanguageSelectOptions,
    recognitionLanguages,
    onRecognitionLanguagesChange,
    onSaveButtonClick,
  };
};
